import React from 'react'
import ContactButton from '../components/ContactButton'

const ContactsButtons = ({handleClick}) => {
    let data = ["Get Quotation","Contact Us","Book Service"]

  return (
    <div className='container p-2 d-flex justify-content-between align-items-stretch'>
        {data.map((x)=>{
            return <ContactButton item={x} handleClick={handleClick}/>
        })}
 
    </div>
  )
}

export default ContactsButtons