import React, { createRef, useEffect, useRef } from 'react'
import "../App.css"
const Video = ({check,handleClick}) => {
  const vi = createRef()
  useEffect(()=>{
    vi.current.play();
    // vi.current.play
  },[])
  return (
    <div style={{width:"100%",position:"relative"}}>
        <video id="contextmenu"  src={!check ?'https://res.cloudinary.com/dvc56ayit/video/upload/v1722100813/gettyimages-1401663965-640_adpp_jsu67q.mp4':'https://res.cloudinary.com/dvc56ayit/video/upload/v1722880626/istockphoto-1383746081-640_adpp_is_gate5r.mp4'} autoPlay={true} autoSave={false} loop muted playsinline style={{width:"100%",height:"100%",backgroundColor:'black'}} controls={false}  ref={vi}>
        {/* <source/> */}
        </video>
      { !check && <div id='videoText'>
          <p  id="videoTextTitle" className='font-monospace'> Commercial and Industrial Cleaning Services</p>

          <button id="getquote" onClick={()=>handleClick()} >Get Free Quote</button>
        </div>}
    </div>
  )
}

export default Video