import React, { useEffect, useRef, useState } from 'react'
import Chatbot from "react-chatbot-kit";
import config from './config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import botImg from "./bot.png"
import { FaRobot } from "react-icons/fa6";
import { FaUser } from "react-icons/fa";
import "../App.css"
import NotificationSound from "./notification-sound.mp3";
import ModalScreen from '../screens/ModalScreen';
import { Button, Form } from 'react-bootstrap';
import { FaUserAlt } from "react-icons/fa";
import { AiFillMessage } from "react-icons/ai";
const ChatBot = () => {
  const [show,setShow] = useState(false)
  const bot = ()=>{
    return <FaUserAlt size={32} style={{marginRight:10}}/>
  }
  const user = ()=>{
    return <FaUser size={32} />
  }

  let [conversation,setCon] = useState([])
  let services = [
    "Office cleaning",
    "Tenancy clean",
    "Carpet cleaning",
    "Upholstery cleaning",
    "Deep clean",
    "Afterbuilders clean",
    "Gutter cleaning",
    "Window cleaning",
    "Jet wash",
    "Patio cleaning",
  ];
  let images = ['https://t4.ftcdn.net/jpg/05/00/97/11/360_F_500971139_o0VnNA3ubOwYZKpHNojurXAiSA1pCVEi.jpg','https://www.londoncleanprof.co.uk/files/posts/End_of_tenancy_cleaning_tips_a.jpg',
    'https://t3.ftcdn.net/jpg/02/18/97/04/360_F_218970417_HqekNsoEJHkbN14V89RKagpY4sY2V6dd.jpg','https://5.imimg.com/data5/SELLER/Default/2023/9/348168939/GS/DC/HP/2151787/upholstery-cleaning-services.jpg',
    'https://img.freepik.com/free-photo/professional-cleaning-service-person-using-steam-cleaner-office_23-2150520644.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1725408000&semt=ais_hybrid','https://cdn.fantasticservices.com/local-pages/after-builders-cleaning/mobile/mobile443-coventry-after-builders-cleaners.jpg',
    'https://www.shutterstock.com/shutterstock/videos/1076893334/thumb/1.jpg?ip=x480','https://c0.wallpaperflare.com/preview/249/403/708/bucket-building-cleaner-cleaning.jpg',
   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr5of2gtQjL84167J1E0z1b36PrsFvHaDv4w&s"

  ]

  useEffect(()=>{
    let msg = "Hello ! How may i help you today??"
    setTimeout(()=>{
      setCon([{type: 'bot',msg,input: <Form
        style={{
          backgroundColor: "#73a030",
          color: "black",
          width: "100%",
          padding: 20,
          textAlign: "left",
        }}
      >
        <Form.Group className="mb-1" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter Name" className='form-control-sm '/>
        </Form.Group>
        <Form.Group className="mb-1" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter Email" />
        </Form.Group>
        <Form.Group className="mb-1" controlId="number">
          <Form.Label>Number</Form.Label>
          <Form.Control type="number" placeholder="Enter Number" />
        </Form.Group>
        <Form.Group className="mb-1" controlId="service">
          <Form.Label>Service</Form.Label>
          <Form.Select>
            {services.map((i, j) => {
              return (
                <option value={i} key={j}>
                  {i}
                </option>
              );
            })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-1" controlId="query">
          <Form.Label>Message</Form.Label>
          <Form.Control type="textarea" placeholder="Enter your Message" />
        </Form.Group>
        <Button>Send</Button>
  
      </Form>




      }])
      setShow(true)
    },2000)
},[])



  return (
    <div>

{ show &&      <div id="chatBox" >
        <div>
      {conversation && show? conversation.map((item)=>{
        return <>
        <p>{item.type == 'bot'?bot():null}{item.msg} {item.type == 'user'?user():null}</p>
        {item.input && item.input}
        </>
      }):null}
      
</div>

      </div>}

      <div >
      <FaUserAlt style={{fontSize:'8vh',float:'right',zIndex:1000}} className='m-1'  onClick={()=>setShow(!show)} />
      </div>
    </div>
  )
}

export default ChatBot