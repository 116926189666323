import React from 'react'

const ContactButton = ({item,handleClick}) => {
  return (
    <button id="btnContact" onClick={()=>handleClick(item)}>
        <p>
            {item}
        </p>
        </button>
  )
}

export default ContactButton