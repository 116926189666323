import React from 'react'
import "../App.css"
const CoreServiceCard = ({item}) => {
  return (
    <div class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front d-flex justify-content-center align-items-center">
      <p style={{fontSize:'2em'}} >{item.title}</p>
    </div>
    <div class="flip-card-back p-2">
      <p style={{fontSize:'0.8em'}}>{item.text}</p>
    </div>
  </div>
</div>
  )
}

export default CoreServiceCard