import React from 'react'
import "../App.css"
const ServiceCard = ({service,imgS,text}) => {
  return (
    // <div className='card m-4' id="card" >
    //    <div>
    //     <img id='cardImg' src={imgS}/>
    //     </div>
    //     <div className='overflow-hidden'>
    //     <p className='m-3 ' style={{fontSize:'1.5em'}}>
    //         {service}
    //     </p>
    //     </div>
      
    // </div>

    <div className="flip-card1 m-4" >

    <div class="flip-card-inner">
      <div class="flip-card-front ">
      <img id='cardImg' src={imgS}/>
      <p  style={{fontSize:'1.5em'}}>
         {service}
        </p>
      </div>
      
      <div class="flip-card-back p-2">
        <p className='font-monospace fw-bold'>{service}</p>
        <p style={{fontSize:'0.8em'}}>{text}</p>
      </div>
    </div>
  </div>
  )
}

export default ServiceCard