import React from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import "../App.css"
import logo from "./Spot.png"
const Header = () => {
  let services = [
    "Office cleaning",
    "Tenancy clean",
    "Carpet cleaning",
    "Upholstery cleaning",
    "Deep clean",
    "Afterbuilders clean",
    "Gutter cleaning",
    "Window cleaning",
    "Jet wash",
    "Patio cleaning",
];
  return (
    <Navbar expand="lg" className="navbar p-0 m-0" style={{backgroundColor:'#8BCB28',color:'white'}} >
      <Container className='p-0'>
        <Navbar.Brand href="/" style={{color:'white',fontWeight:700}} className='font-monospace head'>
        <img
              src={logo}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
              id="logo"
            />
       <p id="headT">Spotclean Solutions Ltd</p> </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className='justify-content-center'>
          <Nav
            className=""
            navbarScroll

          >
            <Nav.Link href="/" style={{color:"white"}}>Home</Nav.Link>
            <Nav.Link href="aboutus"  style={{color:"white"}}>About Us</Nav.Link>
            <NavDropdown title="Services"  id="dropT" >
              {services.map((i,ind)=>{
                return <NavDropdown.Item href={`#${i}`}id="drop" style={{color:"white"}} key={ind}>
               
   {i}
              </NavDropdown.Item>
              })}
              

            </NavDropdown>
            <Nav.Link href="contact" style={{color:"white"}}>Contact</Nav.Link>
            <Nav.Link href="gallery"  style={{color:"white"}}>Gallery</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header