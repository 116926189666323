import React from 'react'
import Nav from 'react-bootstrap/Nav';
import { FaFacebook } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { AiFillTikTok } from "react-icons/ai";
const Navbar = () => {
  return (
    <Nav className="justify-content-end" activeKey="/home">
        <Nav.Item>
          <Nav.Link href="https://m.facebook.com/SpotcleanSolutionsLTD" ><FaFacebook size={24} style={{color:'#3b5998'}}/></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="https://www.instagram.com/spotcleansolutionsltd"><FaSquareInstagram size={24} style={{color:'#ED5F40'}}/></Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="https://www.tiktok.com/@spotcleansolutions"><AiFillTikTok size={24} style={{color:'#2af0ea'}}/></Nav.Link>
        </Nav.Item>
      </Nav>
  )
}

export default Navbar