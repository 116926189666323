import logo from './logo.svg';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Video from './components/Video';
import OurServices from './screens/OurServices';
import CoreValues from './screens/CoreValues';
import ContactsButtons from './screens/ContactsButtons';
import AboutUs from './screens/AboutUs';
import Footer from './components/Footer';
import ContactUs from './screens/ContactUs';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Homescreen from './screens/Homescreen';
import ChatBot from './components/ChatBot';
import Trial from './components/Trial';
import { HelmetProvider } from 'react-helmet-async';
import Gallery from './screens/Gallery';
function App() {

  const router = createBrowserRouter([
    {
      path: "/",
       element:<Homescreen/>
      // element:<Trial/>
    },
    {
      path: "/contact",
       element:<ContactUs/>
    },
    {
      path: "/aboutus",
      element:<AboutUs/>
    },
    {
      path:'/gallery',
      element:<Gallery/>
    }
  ]);
  return (
    // <HelmetProvider context={helmetContext}>
    <div className="App ">
    <Navbar/>
    <Header/>

    <RouterProvider router={router}/>
    <div style={{position:'fixed',bottom:10,right:0}}><ChatBot/></div>
    <Footer/>
    </div>
    // </HelmetProvider>
  );
}

export default App;
