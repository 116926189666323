import React from "react";
import { Container } from "react-bootstrap";
import CoreServiceCard from "../components/CoreServiceCard";

const CoreValues = () => {
  let data = [
    {
      title: "Reliability",
      text: "We understand that our clients rely on us to maintain a clean and safe environment for their employees and customers. That's why we make reliability one of our core values. We show up on time, every time, and we always deliver high-quality cleaning services that meet or exceed our clients' expectations.",
    icon:''
    },
    {
        title: "Attention to Detail",
        text: "We believe that the little things matter when it comes to commercial cleaning. That's why we pay close attention to detail in everything we do. From dusting hard-to-reach areas to disinfecting high-touch surfaces, we take pride in our work and strive to leave every space we clean looking its best.",
      icon:''
      },
      {
        title: "Customer Satisfaction",
        text: "Our ultimate goal is to make our clients happy. We believe that customer satisfaction is the key to building long-term relationships and growing our business. That's why we go above and beyond to ensure that our clients are completely satisfied with our services.",
      icon:''
      },
      {
        title: "Time Efficiency",
        text: "We understand that time is a valuable resource for our clients, which is why we make time efficiency one of our core values. We work quickly and efficiently to complete our cleaning tasks without sacrificing quality. Our team is trained to use the latest cleaning techniques and equipment to get the job done right the first time, saving our clients time and money.",
        icon:''
      },

  ];
  return (
    <div
      className="overflow-hidden p-3 text-center "
      style={{ backgroundColor: "#8BCB28" }}
    >
      <h3 className="pageTitle  m-3 overflow-hidden font-monospace" style={{color:'#405e13'}}>
        Our Core Values
      </h3>

      <div
        style={{ overflow: "hidden" ,textAlign:'center'}}
        className="d-flex justify-content-center flex-wrap align-items-center text-center"
        id="core"
      >

{data.map((s)=>{
                return <CoreServiceCard item={s}/>
            })}

      </div>
      {/* <button id="viewMore" className='m-3 overflow-hidden' >View More</button>   */}
    </div>
  );
};

export default CoreValues;
