import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../App.css";
import ServiceCard from "../components/ServiceCard";
const OurServices = () => {
  let services = [
    {
      category: "Internal Cleaning",
      services: [
        "Office cleaning",
        "Tenancy clean",
        "Carpet cleaning",
        "Upholstery cleaning",
        "Deep clean",
        "Afterbuilders clean",
      ],
    },
    {
      category: "External Cleaning",
      services: [
        "Gutter cleaning",
        "Window cleaning",
        "Jet wash",
        "Patio cleaning",
      ],
    },
  ];
  let images = [
    "https://t4.ftcdn.net/jpg/05/00/97/11/360_F_500971139_o0VnNA3ubOwYZKpHNojurXAiSA1pCVEi.jpg",
    "https://www.londoncleanprof.co.uk/files/posts/End_of_tenancy_cleaning_tips_a.jpg",
    "https://t3.ftcdn.net/jpg/02/18/97/04/360_F_218970417_HqekNsoEJHkbN14V89RKagpY4sY2V6dd.jpg",
    "https://5.imimg.com/data5/SELLER/Default/2023/9/348168939/GS/DC/HP/2151787/upholstery-cleaning-services.jpg",
    "https://img.freepik.com/free-photo/professional-cleaning-service-person-using-steam-cleaner-office_23-2150520644.jpg?size=626&ext=jpg&ga=GA1.1.2008272138.1725408000&semt=ais_hybrid",
    "https://cdn.fantasticservices.com/local-pages/after-builders-cleaning/mobile/mobile443-coventry-after-builders-cleaners.jpg",
    "https://www.shutterstock.com/shutterstock/videos/1076893334/thumb/1.jpg?ip=x480",
    "https://c0.wallpaperflare.com/preview/249/403/708/bucket-building-cleaner-cleaning.jpg",
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTr5of2gtQjL84167J1E0z1b36PrsFvHaDv4w&s",
    "https://www.rightwash.uk/wp-content/uploads/2022/11/Simple-patio-cleaning-methods.jpg",
  ];
  let text = [
    "Here at Spotclean Solutions Ltd our specially trained cleaners provide a quick and reliable service for small to medium size offices all around London, on a daily, weekly or monthly basis. At Spotclean Solutions Ltd we understand the importance of maintaining a clean and tidy working environment, the appearance of your office will influence the way potential customers feel about the service provided by you.",
    "Spotclean Solutions Ltd have trained and professional cleaning teams to clean flats, houses and apartments in the London area, ready for new tenants to move into. Our cleaners can work at anytime including weekends and the property must be vacated at the time of cleaning. We know that in order to let a property, high standards of cleanliness are paramount, and Spotclean Solutions Ltd can ensure this is done.",
    "Maintaining your carpets in your home or place of work is always beneficial and affects your mood in positive ways, which is why we offer affordable carpet cleaning. Let us know if its a single/double room, stairs, home or workplace you want cleaning and we will carry out your requests.",
    "Spotclean Solutions Ltd cleaners can restore the colour and beauty of your upholstered furniture, by cleaning deep down, helping to extend the life of your furniture too. Our upholstery cleaners are fully trained and are efficient whilst taking care of your furniture. Please contact us to discuss further about the upholstery cleaning services we offer.",
    "Deep cleaning services are typically recommended for spaces that have not been cleaned in a while, or for spaces that require a more thorough cleaning due to a specific event or circumstance, such as moving into a new home or office, preparing for a special event, or dealing with a pest infestation.",
    "After builders cleaning service is typically performed by a team of trained and experienced cleaners who have the expertise and equipment to handle the unique cleaning needs of construction sites. This type of cleaning service is essential for ensuring that the space is safe, clean, and ready for use after construction work is completed.",
    "Gutter cleaning service is a specialized cleaning service that is designed to remove debris, such as leaves, twigs, and dirt, from gutters and downspouts. This type of cleaning service is typically required to prevent clogs and blockages in the gutters, which can lead to water damage and other problems.",
    "Your windows can have a dramatic affect on the overall look of your home, office or building. When you have visitors over or new customers, if the windows are dirty this could potentially put them off visiting you/buying from you. Spotclean Solutions Ltd has staff dedicated to cleaning windows, both inside and outside. They have been trained to the highest standards to make sure you have a clear view.",
    "Jet wash service, also known as pressure washing, is a cleaning service that uses high-pressure water to clean and remove dirt, grime, and other debris from surfaces. This type of cleaning service is typically used for outdoor surfaces, such as driveways, patios, decks, and sidewalks.",
    "Patio cleaning service is a specialized cleaning service that is designed to clean and restore outdoor patios. This type of cleaning service is typically required to remove dirt, grime, and other debris from patios, which can accumulate over time and make the patio look dull and unattractive."
  ];
  return (
    <div className="text-center p-0">
      <h3 className="pageTitle mt-3 mb-3 container overflow-hidden font-monospace">
        Our Services
      </h3>

      <p className="mt-3 mb-3">
        We serve commercial clients across London and the South East. Our
        commitment is to provide excellent service consistently. We guarantee
        excellent service every time and promise that our cleaners are reliable
        and hardworking, ensuring a relationship built on mutual respect and
        satisfaction.
      </p>
      <div style={{ backgroundColor: "#9bdd3fcc",width:"100%"}}>
        {services.map((item) => {
          return (
            <div
              className="p-3"
              style={{ backgroundColor: "#9bdd3fcc", width: "100%" }}
            >
              <p
                className="m-3 pageTitle font-monospace"
                style={{ color: "#405e13", fontWeight: 700 }}
              >
                {item.category}
              </p>

              <div
                style={{ overflow: "hidden", textAlign: "center" }}
                className="d-flex justify-content-center flex-wrap align-items-center text-center"
              >
               

               
                {item.services.map((s, idx) => {
                  return (
                   
              
                      <ServiceCard
                        service={s}
                        imgS={
                          item.category == "External Cleaning"
                            ? images[idx + 6]
                            : images[idx]
                        }
                        text={item.category == "External Cleaning"
                        ? text[idx + 6]
                        : text[idx]}
                      />
                   
          
                  );
                })}
            
              </div>
            </div>
          );
        })}
        
      </div>

      {/* <button id="viewMore" className='m-3 overflow-hidden' >View More</button>   */}
    </div>
  );
};

export default OurServices;
