import React from 'react'
import { Image } from 'react-bootstrap'
import "../App.css"

const GalleryCard = ({src,handleClick}) => {
    console.log(src)

  return (
    <div ><Image src={src} rounded className='image-gallery' onClick={()=>handleClick(src)}/>
  
    </div>
  )
}

export default GalleryCard