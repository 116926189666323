import React from "react";
import { Button, Form } from "react-bootstrap";

const ModalScreen = ({ i ,handleClose}) => {
  let services = [
    "Office cleaning",
    "Tenancy clean",
    "Carpet cleaning",
    "Upholstery cleaning",
    "Deep clean",
    "Afterbuilders clean",
    "Gutter cleaning",
    "Window cleaning",
    "Jet wash",
    "Patio cleaning",
  ];

  const getContent = () => {
    switch (i) {
      case "Get Quotation":
        return (
          <>
       
            <Form
              style={{
                backgroundColor: "#b4ff43",
                color: "black",
                width: "100%",
                padding: 20,
                textAlign: "left",
              }}
            >
                   <h4 style={{textAlign:'center'}}>{i}</h4>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Name" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="number">
                <Form.Label>Number</Form.Label>
                <Form.Control type="number" placeholder="Enter Number" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="service">
                <Form.Label>Service</Form.Label>
                <Form.Select>
                  {services.map((i, j) => {
                    return (
                      <option value={i} key={j}>
                        {i}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              
              <Button variant="secondary" className="m-2" onClick={()=>handleClose()}>Cancel</Button>
              <Button>Submit</Button>
        
            </Form>
          </>
        );

        break;

      default:
        return null;
        break;
    }
  };
  return <div>{getContent()}</div>;
};

export default ModalScreen;
