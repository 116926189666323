import React, { useState } from "react";
import "../App.css";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
const ContactUs = () => {
  let formFields = [
    { title: "Name", type: "text" },
    { title: "Contact Number", type: "text" },
    { title: "Services", type: "dropdown" },
    { title: "Email", type: "email" },
    { title: "Query", type: "textbox" },
  ];
  const [form, setForm] = useState({});
  let services = [
    "Office cleaning",
    "Tenancy clean",
    "Carpet cleaning",
    "Upholstery cleaning",
    "Deep clean",
    "Afterbuilders clean",
    "Gutter cleaning",
    "Window cleaning",
    "Jet wash",
    "Patio cleaning",
  ];

  return (
    <Container className="align-content-center mt-3">
      <h3>
      Contact us
      </h3>
      <p>
      Please get in touch for a FREE quote, query or any other matter.
      </p>
<div style={{textAlign:'left'}} className="m-4">
      <p className="fw-bold">Address :- </p>
      <p>Spotclean Solutions Ltd, 20 Clayton Road,
      Hayes, UB3 1AZ</p>
      <p className="fw-bold">Contact Number : -</p>
      <p>02033973422   Or   01753290930</p>
      <p className="fw-bold">Email:-</p>
      <p> info@spotcleansolutions.co.uk</p>
      </div>
<p className="fw-bold">
Please use our form or the contact details below.
</p>

<Form
              style={{
                color: "black",
                width: "100%",
                padding: 20,
                textAlign: "left",
              }}
            >
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter Name" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter Email" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="number">
                <Form.Label>Number</Form.Label>
                <Form.Control type="number" placeholder="Enter Number" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="service">
                <Form.Label>Service</Form.Label>
                <Form.Select>
                  {services.map((i, j) => {
                    return (
                      <option value={i} key={j}>
                        {i}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
              
              <Button style={{float:"right"}}>Submit</Button>
        
            </Form>
            
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.415567958266!2d-0.42785292439301364!3d51.50559151083673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487673db739b12c9%3A0xcaecf8f4d00c620f!2sSpotclean%20Solutions%20Ltd!5e0!3m2!1sen!2sin!4v1726574962435!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </Container>
  );
};

export default ContactUs;
