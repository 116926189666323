import React, { useState } from "react";
import { RowsPhotoAlbum } from "react-photo-album";
import "react-photo-album/rows.css";
import "../App.css"
import GalleryCard from "../components/GalleryCard";
import { IoMdCloseCircle } from "react-icons/io";
import { IoChevronForwardOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMedia, setActiveMedia] = useState(null);
const data = ["https://res.cloudinary.com/dvc56ayit/image/upload/v1727794575/w4_tj9gam.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794575/w3_a6dlbw.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794575/w2_z76jmm.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794574/o3_tstwqp.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794575/p4_igbsol.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794574/p3_sz2smz.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794574/w1_abtuty.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794574/o4_vvnypt.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794574/o2_et0vlo.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794573/o1_yiw7vd.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794573/c1_dhf7o2.png","https://res.cloudinary.com/dvc56ayit/image/upload/v1727794573/h1_c4s1m6.png"]
  const openModal = (media) => {
    setActiveMedia(media);
    setIsOpen(true);
  };

  const closeModal = () => {  
    setIsOpen(false);
    setActiveMedia(null);
  };
  const handleClick=(i)=>{
    openModal(i)
  }
  
  const handleForward = ()=>{
    let ind = data.indexOf(activeMedia)
    setActiveMedia(data[ind+1])
  }

  const handleBackward = ()=>{
    let ind = data.indexOf(activeMedia)
    setActiveMedia(data[ind-1])
  }
  return (
    <div className="gallery container d-flex-column">
   {data.map((x,ind)=>{
    return <GalleryCard src={x} key={ind} ind={ind} handleClick={handleClick}/>
   })}

   {isOpen && <div className="modald container" >
    {/* <IoChevronForwardOutline style={{position:'absolute',top:"50%",right:20,cursor:'pointer'}}  size={36} onClick={()=>handleForward()}/> */}
    {/* <IoIosArrowBack style={{position:'absolute',top:"50%",left:20,cursor:'pointer'}} size={36} onClick={()=>handleBackward()}/> */}
    <img src={activeMedia}/>
    <IoMdCloseCircle className="modal-icon" size={36} onClick={()=>closeModal()}/>
    
    </div>}


    </div>
  );
};

export default Gallery;

