import React from 'react'
import { Container, Nav } from 'react-bootstrap'

const Footer = () => {
  return (
    <div style={{backgroundColor:'#8BCB28',textAlign:'center'}} className='mt-3 text-center p-2'>

        <Container>
           
                <p style={{color:'white',textAlign:'center'}}>Copyright © 2024 Spotclean Solutions Ltd- All Rights Reserved.</p>
            
        </Container>
    </div>
  )
}

export default Footer