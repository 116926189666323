import React, { useState } from 'react'
import Video from '../components/Video'
import OurServices from './OurServices'
import CoreValues from './CoreValues'
import ContactsButtons from './ContactsButtons'
import AboutUs from './AboutUs'
import ModalScreen from './ModalScreen'
import ChatBot from '../components/ChatBot'

const Homescreen = () => {
    const [show,setShow] = useState(false)
    const handleClick = (i)=>{
        setShow(!show)
    }

    const handleClose=()=>{
        setShow(false)
    }
  return (
    <div  style={{position:'relative'}}>
         <Video handleClick={handleClick}/>
    <OurServices    />
   <hr></hr>
    <CoreValues/>
    <hr></hr>
    <ContactsButtons handleClick={handleClick}/>

    {show && <div id="modalS" >
        <ModalScreen i="Get Quotation" handleClose={handleClose}/>
        </div>}
        
       </div>
  )
}

export default Homescreen