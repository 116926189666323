import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Video from '../components/Video'
import { Link, useLocation } from 'react-router-dom'

const AboutUs = () => {
  let location = useLocation()
  let currectRoute = location.pathname
  
  return (
    <Container className='mt-3'>
        <Row>
            <Col id="aboutCol">
            <h3 className='pageTitle m-3 container overflow-hidden' style={{color:'white'}} >About Us</h3>
            <div>

{currectRoute == "/aboutus" && <p style={{color:'white',textAlign:'left'}}>At Spotclean Solutions Ltd, we believe that a clean space is the foundation for a productive, healthy, and happy environment. With over [X years] of experience, our team is dedicated to providing top-tier cleaning services tailored to the unique needs of every client.

Our mission is simple: to deliver impeccable cleaning results with professionalism, consistency, and a personal touch. Whether you need commercial, residential, or specialized cleaning services, we combine industry-leading techniques with eco-friendly products to ensure your space is not only spotless but safe and sustainable.

At Spotclean Solutions Ltd, we pride ourselves on our attention to detail, reliability, and commitment to excellence. Our trained and certified cleaning professionals go above and beyond to meet your expectations, ensuring that every corner is thoroughly cleaned and maintained.<br/>
<br/>

Why Choose Us?
<ul>
  <li>
 <b>Experienced & Trustworthy Team:</b>  All our cleaners are fully vetted and trained to the highest standards.
  </li>
  <li>
  <b>Customized Cleaning Solutions:</b>  We tailor our services to suit your specific needs, whether it’s a one-time deep clean or a regular maintenance schedule.
  </li>
  <li>
  <b>Eco-Friendly Products:</b>  We prioritize green cleaning products that are safe for both you and the environment.
  </li>
  <li>
  <b>100% Satisfaction Guarantee:</b>  Your satisfaction is our top priority. We’re not happy until you are.
  </li>
</ul>

Join the countless satisfied clients who trust Spotclean Solutions Ltd for their cleaning needs. Let us transform your space into a cleaner, fresher, and more welcoming environment!</p>}
            </div>
            </Col>
        </Row>
    </Container>
  )
}

export default AboutUs